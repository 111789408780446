import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { Game } from '../entities/game';
import { faSpinner} from '@fortawesome/pro-duotone-svg-icons';
import { GameMode } from '../entities/game-mode';
import { ThemeService } from '../services/theme.service';

@Component({
  selector: 'app-tile',
  templateUrl: './tile.component.html',
  styleUrls: ['./tile.component.scss']
})
export class TileComponent implements OnChanges {
  faSpinner = faSpinner;

  @Input() gameMode: GameMode;
  @Input() level: number;
  @Input() tile: number;
  @Input() scoreTile: number;
  @Input() amount: number;
  @Output() tileclick: EventEmitter<any> = new EventEmitter();
  
  @Input()
  get game (): Game { return this._game; }
  set game (game: Game) {
    if (game != this._game) {
      if (!game || !this._game || game.game_id != this._game.game_id) {
        this.selectedTile = false;
      }
      this._game = game;
    }
  }
  private _game: Game;
    
  @Input()
  get forceTile (): number { return 0 }
  set forceTile (forceTile: number) {
    if (this.tile == forceTile) {
      setTimeout(() => this.clickTile(), 0); 
    }
  }

  public selectedTile: boolean = false;
  public state: string = 'plain';
  public style: string = 'blue';
  private bombTile: boolean;

  constructor(private themeService: ThemeService) { 

  }

  ngOnChanges(): void {
    this.bombTile = this.game.CheckIsBomb(this.level, this.tile, this.gameMode, this.scoreTile);
    this.selectedTile = this.game.GetSelectedTile(this.level, this.tile);
    var rowDifficulty = this.gameMode.checkLevel(this.level);  
  
    this.style = this.themeService.GetColour(rowDifficulty);
    
    if (!this.game || !this.game.level) 
      this.state = 'plain';
    else if (this.level > this.game.level)
      this.state = 'plain';
    else if (this.level <= this.game.level) {
      if (!this.bombTile) {
        this.state = 'safe';
        this.style = this.themeService.GetSafeColour(rowDifficulty);   
        if (this.selectedTile) this.style = 'greenselected'
      } else {
        this.state = 'bomb';
        this.style = 'na';
      }
    }

  }

  public clickTile() {
    //Can only emit the click if the game is on this level
    //Ideally we can also disable the CSS so the user is not confused
    if ((this.game.level == this.level - 1 || this.gameMode.custom) && (!this.game.submittedLevel || this.game.submittedLevel == this.level - 1) && this.state != 'loading') {
      this.state = 'loading';
      this.selectedTile = true;
      this.tileclick.emit(null);
    }
  }

  public getPreciseAmount(amount: number) {
    if (amount < 1000)
      return amount.toPrecision(3);
    else 
      return amount.toPrecision(4);
  }
}
