import { EventEmitter } from '@angular/core';
import { GameMode } from './game-mode';

export class Game {
    game_id: number;
    player_address: string;
    status: number;
    level: number;
    submittedLevel: number;
    bet_amount: number;
    balance: number;
    active_game_num: number;
    max_level: number = 6;
    game_mode: number = 1;
    bombs: string;
    selected_tiles: string;
    bombTiles: number[] = []; // Used for local record only

    public emulateTileclick: EventEmitter<any> = new EventEmitter();

    constructor(gameMode: number) {
        this.game_mode = gameMode;
    }

    CheckIsBomb(level: number, tileNumber: number, gameMode: GameMode, scoreTile: number): boolean {
        if (scoreTile) {
            if (!gameMode.checkWin(tileNumber, scoreTile, level)) {
                return true;
            } else {
                return false;
            }
        }
        if (this.bombs) {
            var bombList = this.bombs.split(',');
            if (bombList.length >= level) {
                var bombFound = bombList[level - 1].indexOf(tileNumber + '');
                return bombFound >= 0;
            }
        }
        return false;
    }

    GetSelectedTile(level: number, tileNumber: number): boolean {
        if (this.selected_tiles) {
            var tilesList = this.selected_tiles.split(',');
            if (tilesList.length >= level) {
                return parseInt(tilesList[level - 1]) == tileNumber;
            }
        }
        return false;
    }

    async EmulateTileclick(tile: number) {
        debugger;
        this.emulateTileclick.emit({level: this.level, tile: tile});
    }
}
