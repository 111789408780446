import { Component } from '@angular/core';
import { faChevronDoubleRight, faChevronDoubleLeft, faSpinner, faMusic, faMusicSlash, faVolumeMute, faVolume, faQuestionCircle } from '@fortawesome/pro-duotone-svg-icons';
import { IconContractService } from './services/icon.service';
import { IconexService } from './services/iconex.service';
import { Game } from './entities/game';
import { GameService } from './services/game.service';
import { ReversePipe } from 'ng-pipes';
import { CookieService } from './services/cookie.service';
import { IconbetService } from './services/iconbet.service';
import { exit } from 'process';
import { GameMode } from './entities/game-mode';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from './services/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { IScriptResult } from './entities/script-result';
import { ScriptService } from './services/script.service';
import { environment } from '../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ThemeService } from '../app/services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./theme/'+environment.theme+'/app.component.scss'],
  providers: [ReversePipe]
})

export class AppComponent {
  title = 'DAOLevels';
  faChevronDoubleRight = faChevronDoubleRight;
  faChevronDoubleLeft = faChevronDoubleLeft;
  faSpinner = faSpinner;
  faMusic = faMusic;
  faMusicSlash = faMusicSlash;
  faVolumeMute = faVolumeMute;
  faQuestionCircle = faQuestionCircle;
  faVolume = faVolume;
  public address: string = '';
  public userSeed: string = '';
  public balance: number;
  public game: Game = new Game(0);
  public betAmount: number = 1;
  public gameLoading = false;
  public cashoutLoading = false;
  public tileLoading = false;
  public openGames: Game[];
  public lostAnimate: boolean = false;
  public winAnimate: boolean = false;
  public playing: boolean = false;
  public playingSFX: boolean = true;
  public audio: HTMLAudioElement = new Audio();
  public gameModes: GameMode[];
  public selectedGameMode: GameMode;
  public paramGameId: number;
  public windowLocation: Location = window.parent.location;
  public closeResult: string;
  public autoPlay: boolean;
  public autoPlayStrat: string = "Always1";
  public autoPlayCashout: number = 6;
  public autoPlayLastTile: number;
  public autoPlayLastBomb: number;
  public autoPlayForceTile: number;
  public autoPlayCustomStrat: string;
  public autoStop: boolean;
  public autoStopGames: number = 10;
  public autoStopBalance: number = 1;
  public autoPlayCurrentGame: number = 0;
  public autoPlayGamesLost: number = 0;
  public autoPlayGamesCashout: number = 0;
  public autoPlayGamesWon: number = 0;
  public autoPlayRetries: number = 0;
  public autoPlayMaxRetries: number = 5;
  public autoPlayCustomScript: string = "return {tile: 1};";
  public autoPlayExampleScript: string;
  public autoPlayCustomScriptError: string;
  public autoPlayCustomScriptContext: any = {};
  public autoPlayCustomScriptNextBet: number;
  public autoPlayLastGameStatus: string;
  public theme: string;

  constructor(
    public iconService: IconContractService,
    public iconexService: IconexService,
    public gameService: GameService,
    public reverse: ReversePipe,
    public cookieService: CookieService,
    public iconbetService: IconbetService,
    public route: ActivatedRoute,
    private notifyService: NotificationService,
    private modalService: NgbModal,
    public scriptService: ScriptService,
    private translate: TranslateService
  ) {
    translate.setDefaultLang('en');
    this.gameModes = this.gameService.GetDefaultGameModes();
    this.selectedGameMode = this.gameModes[1];

    var splits = this.windowLocation.search.split('game_id=');
    if (splits.length > 1)
      this.paramGameId = parseInt(splits[1]);

    this.autoPlayCustomScript = scriptService.getSamples()[0].script;
  }

  public ngAfterViewInit() {
    //resize the parent iframe to fit the game
    //this fixes the issue with mobile view
    let iframe = parent.document.getElementById('iconbet-game-iframe') as HTMLIFrameElement
    iframe.style.height = '1150px';//iframe.contentWindow.document.body.scrollHeight + 'px';

    let minHeight = parent.document.getElementsByClassName("embed-responsive embed-responsive-3by2")[0] as HTMLDListElement;
    minHeight.style.setProperty("min-height", "", "important");

    let height = parent.document.getElementsByClassName("embed-responsive embed-responsive-3by2")[0] as HTMLDListElement;
    height.style.setProperty("height", iframe.contentWindow.document.body.scrollHeight + 25 + 'px', "important");
  }

  public async ngOnInit() {
    const myStorage = window.localStorage;
    var value: string = myStorage.getItem('locale');
    if(value) {
      this.translate.use(value);
    }

    this.LoadAllGameModes();

    this.LoadLevelMultipliers(this.selectedGameMode);
    //this.LoadMinBet();
    this.LoadMaxBet(this.selectedGameMode);

    //Subscribe to user seed changes from iconbet cookie
    this.iconbetService.userSeedEvent.subscribe((userSeed: string) => this.userSeed = userSeed);

    //subscribe to address changes from iconbet cookie
    this.iconbetService.addressEvent.subscribe((address: string) => {
      this.address = address;
      console.log('Check cookie address : ' + this.cookieService.getCookie('wallet_address'));
      this.LoadBalance();
      this.LoadCurrentGames();
      this.gameLoading = false;
      
      this.scriptService.restoreScript(address).then(script => {
        if (script && script != 'null')
          this.autoPlayCustomScript = script;
      });
    });
  }

  async CheckLogin() {
    if (this.address && this.address != '')
      return;

    if (window.location.host.indexOf('localhost') >= 0 || window.location.host.indexOf('web.app') >= 0) {
      this.gameLoading = true;
      this.address = await this.iconexService.selectAddress();
      console.log('Logging in without Iconbet');
      this.LoadBalance();
      this.LoadCurrentGames();
      this.gameLoading = false;
      
      this.scriptService.restoreScript(this.address).then(script => {
        if (script && script != 'null')
          this.autoPlayCustomScript = script;
      });
    } else if (!this.cookieService.checkCookie() || !this.cookieService.getCookie('wallet_address')) {
      this.gameLoading = true;
      console.log('Asking for user login');
      this.iconbetService.askForLogin();
    }
  }

  async PlaySFX(value: boolean) {
    this.playingSFX = value;
  }

  async PlayAmbient(on: boolean) {
    if (on) {
      this.audio.src = "assets/sound/ambient_sound.mp3"
      this.audio.addEventListener('ended', function () {
        this.currentTime = 0;
        this.play();
      }, false);
      this.audio.play();
      this.playing = true;
    }
    else {
      this.audio.pause();
      //  myAudio.currentTime = 0;
      this.playing = false;
    }
  }

  async LoadBalance() {
    this.balance = await this.iconService.getBalance(this.address);
  }

  async LoadMinBet(gameMode: GameMode) {
    gameMode.minBet = await this.gameService.getMinBet();
  }

  async LoadMaxBet(gameMode: GameMode) {
    if (!gameMode.custom)
      gameMode.maxBets = await this.gameService.getMaxBet(gameMode.id)
  }

  async LoadLevelMultipliers(gameMode: GameMode) {
    if (!gameMode.custom)
      gameMode.levelMultipliers = await this.gameService.getLevelMultipliers(gameMode.id);
  }

  async LoadAllGameModes() {
    if (!this.selectedGameMode.custom) {
      this.gameModes = await this.gameService.GetAllGameModes();
      this.gameModes.forEach(gameMode => {
        if (gameMode.id == this.selectedGameMode.id) {
          this.selectedGameMode = gameMode;
          this.LoadLevelMultipliers(this.selectedGameMode);
          this.LoadMaxBet(this.selectedGameMode);
        }
      });
    }
  }

  async LoadCurrentGames() {
    //Call the method and show user any current games if there is any
    this.openGames = await this.gameService.getOpenGames(this.address);

    if (this.paramGameId && this.openGames && this.openGames.length > 0) {

      this.openGames.forEach(gameElement => {
        if (this.paramGameId == gameElement.game_id) {
          this.setActiveGame(gameElement);
        }
      });
    } else if (this.openGames && this.openGames.length > 0) {
      this.setActiveGame(this.openGames[0]);
    }
  }

  async RefreshGame(forceRefresh: boolean = true) {
    this.openGames = await this.gameService.getOpenGames(this.address);

    var gameId = this.game.game_id;
    if (forceRefresh) {
      this.game = new Game(0);
    }
    if (this.openGames && this.openGames.length > 0) {
      this.openGames.forEach(gameElement => {
        if (gameId == gameElement.game_id) {
          if (forceRefresh) {
            this.setActiveGame(gameElement);
          } else {
            this.game.balance = gameElement.balance;
            this.game.bet_amount = gameElement.bet_amount;
          }
        }
      });

    }
  }

  private setGameResult(bombTile: number) {
    if (this.selectedGameMode.custom) this.game.level = 0;
    this.game.level++;
    this.game.bombTiles[this.game.level] = bombTile;
    this.autoPlayLastBomb = bombTile;
  }

  public async SelectTile(squareId: number) {
    this.autoPlayLastTile = squareId;
    if (this.selectedGameMode.custom || this.game.balance >= 0) {
      this.tileLoading = true;

      var gameResult = -1;
      try {
        if (!this.game.level) this.game.level = 0;
        this.game.submittedLevel = this.game.level + 1;
        if (!this.selectedGameMode.custom)
          gameResult = await this.gameService.selectTile(this.address, this.game.active_game_num, squareId, this.userSeed);
        else {
          await this.CheckLogin();
          gameResult = await this.gameService.customBet(this.address, squareId, this.selectedGameMode.tiles, this.userSeed, this.betAmount);
          this.autoPlayCurrentGame++;
        }
        this.setGameResult(gameResult);
      } catch (ex) {
        this.gameLoading = false;
        this.game.submittedLevel = this.game.level;
        this.notifyService.showError(ex, 'Tile Selection Failed');
        this.tileLoading = false;

        //Glitches happen from time to time, just auto-play again
        this.autoPlayRetries++;
        await this.iconService.delay(2000);
        await this.RefreshGame();
        this.AutoPlay();
        exit;
      }
      this.LoadBalance();

      if (!this.selectedGameMode.checkWin(squareId, gameResult, this.game.level)) {
        this.AnimateLostGame();
        setTimeout(async () => {
          await this.RefreshGame();
          this.tileLoading = false;
          this.AutoPlay();
        }, this.autoPlay ? 500 : 3000);
        this.iconbetService.updateLeaderboard();
        this.LoadAllGameModes();
      } else {
        if (this.game.level == this.getMyMaxLevel(this.betAmount)) {
          this.AnimateWinGame();
          setTimeout(async () => {
            await this.RefreshGame();
            this.tileLoading = false;
            this.AutoPlay();
          }, this.autoPlay ? 500 : 3000);
          this.iconbetService.updateLeaderboard();
          this.LoadAllGameModes();
        }
        else {
          this.AnimateAdvanceLevel();
          this.RefreshGame(false);
          this.tileLoading = false;
          this.AutoPlay();
        }
      }
    }
  }

  public async AnimateLostGame() {
    this.autoPlayLastGameStatus = "lost";
    if (!this.autoPlay && !this.selectedGameMode.custom) {
      this.lostAnimate = true;
      setTimeout(() => this.lostAnimate = false, 3000);
    }
    else {
      this.autoPlayGamesLost++;
      this.notifyService.showError('You have lost the game', 'Game Lost', true);
    }
    if (this.playingSFX) {
      var audio = new Audio();
      audio.src = "assets/sound/Bomb.wav";
      audio.load();
      audio.play();
    }
  }

  public async AnimateWinGame() {
    this.autoPlayLastGameStatus = "won";
    if (!this.autoPlay && !this.selectedGameMode.custom) {
      this.winAnimate = true;
      if (this.selectedGameMode.id != 3)
        setTimeout(() => this.winAnimate = false, 3000);
      else
        setTimeout(() => this.winAnimate = false, 10000);
    }
    else {
      this.autoPlayGamesWon++;
      if (this.getMyMaxLevel(this.betAmount) > 1)
        this.notifyService.showSuccess(`Congratulations !, your game on ${this.selectedGameMode.name} have reached the top level`, `${this.selectedGameMode.name} Game Won`);
      else
        this.notifyService.showSuccess(`Congratulations !, your game on ${this.selectedGameMode.name} have won`, `${this.selectedGameMode.name} Game Won`);
    }
    if (this.playingSFX) {
      var audio = new Audio();
      audio.src = "assets/sound/success.wav";
      audio.load();
      audio.play();
    }
  }

  public async AnimateAdvanceLevel() {
    if (this.playingSFX) {
      var audio = new Audio();
      audio.src = "assets/sound/buttonpress.wav";
      audio.load();
      audio.play();
    }
  }

  public async StartGame(amount: number, gameMode: number) {
    this.CheckLogin();

    if (!this.gameLoading && this.address != '' && !this.tileLoading) {
      console.log("STARTING GAME")
      this.gameLoading = true;
      try {
        var newGame: Game = await this.gameService.StartGame(this.address, amount, gameMode);
        this.autoPlayCurrentGame++;
        this.game = newGame;
        this.LoadBalance();
        this.RefreshGame(false);
        this.autoPlayRetries = 0;
        this.AutoPlay();
      } catch (ex) {
        this.gameLoading = false;
        this.notifyService.showError(ex, 'New Game Failed')
        //Glitches happen from time to time, just auto-play again
        this.autoPlayRetries++;
        await this.iconService.delay(2000);
        await this.RefreshGame();
        this.AutoPlay();
      }
      this.gameLoading = false;
    }
  }

  public autoPlayReset() {
    this.autoPlayCurrentGame = 0;
    this.autoPlayGamesLost = 0;
    this.autoPlayGamesCashout = 0;
    this.autoPlayGamesWon = 0;
    this.autoPlayRetries = 0;

    if (this.autoPlay == true) {
      this.notifyService.enableNotifications()
    } else {
      this.notifyService.disableNotifications()
    }
  }

  public async AutoPlay() {
    if (this.autoPlay == true) {
      if (this.autoPlayRetries >= this.autoPlayMaxRetries) {
        //this.autoPlayReset();
        return;
      }

      setTimeout(() => this.autoPlayForceTile = 0, 0);

      // No game, start a game
      if ((this.selectedGameMode.custom || this.game.level == undefined) && this.autoStop == true && this.autoStopGames > 0 && this.autoPlayCurrentGame >= this.autoStopGames) {
        this.notifyService.showSuccess("Total games completed, stopping", "Auto-Stop Triggered", false, true);
        this.autoPlayReset();
      } else if ((this.selectedGameMode.custom || this.game.level == undefined) && this.autoStop == true && this.autoStopBalance > this.balance) {
        this.notifyService.showWarning("Minimum balance reached, stopping", "Auto-Stop Triggered", false, true);
        this.autoPlayReset();
      } else if (this.game.level == undefined && !this.selectedGameMode.custom) {
        if (this.autoPlayCustomScriptNextBet) this.betAmount = this.autoPlayCustomScriptNextBet;
        this.StartGame(this.betAmount, this.selectedGameMode.id);
        this.autoPlayCustomScriptNextBet = null;
      } else if (this.autoPlayCashout <= this.game.level && this.autoPlayCashout < 6) {
        this.Cashout();
      } else if (this.autoPlayStrat == "Always1") {
        this.autoPlayForceTile = 1;
      } else if (this.autoPlayStrat == "Always2") {
        this.autoPlayForceTile = 2;
      } else if (this.autoPlayStrat == "Always3") {
        this.autoPlayForceTile = 3;
      } else if (this.autoPlayStrat == "AlwaysLast") {
        this.autoPlayForceTile = this.autoPlayLastTile;
      } else if (this.autoPlayStrat == "SecondMod") {
        var d = new Date();
        var n = d.getSeconds();
        this.autoPlayForceTile = (n % this.selectedGameMode.tiles) + 1;
      } else if (this.autoPlayStrat == "Custom") {
        var sequence = this.autoPlayCustomStrat.split(',');
        if (sequence.length - 1 < this.game.level) {
          this.notifyService.showWarning(`Custom Sequence: No tile for level ${this.game.level + 1}`, "Auto-Play Error", false, true);
          this.autoPlayReset();
          return;
        }
        var tile = parseInt(sequence[this.game.level]);
        if (tile >= 1 && tile <= this.selectedGameMode.tiles) {
          this.autoPlayForceTile = tile;
        } else {
          this.notifyService.showWarning(`Custom Sequence: Invalid tile for level ${this.game.level + 1}`, "Auto-Play Error", false, true);
          this.autoPlayReset();
          return;
        }
      } else if (this.autoPlayStrat == "Script") {
        try {
          var scriptResult = await this.executeScript(this.autoPlayCustomScript);
          if (scriptResult.betAmount && !this.selectedGameMode.custom)
            this.autoPlayCustomScriptNextBet = scriptResult.betAmount;
          else if (scriptResult.betAmount)
            this.betAmount = scriptResult.betAmount;
            
          if (scriptResult.cashout == true && this.game.level > 0 && this.selectedGameMode.enableCashout == true) {
            this.Cashout();
          } else if (scriptResult.tile) {
            this.autoPlayForceTile = scriptResult.tile;
          }
        } catch (err) {
          this.notifyService.showWarning(`Custom Script Failure: ${err}`, "Auto-Play Error", false, true);
          this.autoPlayReset();
          return;
        }
      } else if (this.game.level == 0) {
        if (this.autoPlayStrat == "Diag1") this.autoPlayForceTile = 1;
        if (this.autoPlayStrat == "Diag2") this.autoPlayForceTile = 2;
        if (this.autoPlayStrat == "Diag3") this.autoPlayForceTile = 3;
        if (this.autoPlayStrat == "Diag4") this.autoPlayForceTile = 4;
      } else {
        this.autoPlayLastTile++;
        if (this.autoPlayLastTile > this.selectedGameMode.tiles)
          this.autoPlayLastTile = 1;
        this.autoPlayForceTile = this.autoPlayLastTile
      }

    }
  }

  public async Cashout() {
    if (!this.cashoutLoading) {
      if (console) console.log("CASHING OUT")
      this.cashoutLoading = true;
      try {
        await this.gameService.Cashout(this.address, this.game.active_game_num);
        this.autoPlayGamesCashout++;
        this.autoPlayLastGameStatus = "cashout";
        this.game = new Game(0);
        await this.iconService.delay(300); //Just a small delay to make sure the GetOpenGames has been updated
        this.LoadBalance();
        await this.RefreshGame();
        this.iconbetService.updateLeaderboard();
        this.AutoPlay();
      }
      catch (ex) {
        this.cashoutLoading = false;
        this.notifyService.showError(ex, 'Cashout Failed');

        //Glitches happen from time to time, just auto-play again
        this.autoPlayRetries++;
        await this.iconService.delay(2000);
        await this.RefreshGame();
        this.AutoPlay();
      }
      this.cashoutLoading = false;
    }
  }

  public getHalfBet(betAmount: number): number {
    var result: number = betAmount / 2;
    if (result < this.selectedGameMode.minBet)
      result = this.selectedGameMode.minBet;
    return result;
  }

  public getDoubleBet(betAmount: number): number {
    var myMaxBet = this.getMyMaxBet(betAmount);
    var result: number = betAmount * 2;
    if (result > myMaxBet)
      result = myMaxBet;
    return result;
  }

  public getMyMaxBet(betAmount: number): number {
    //var maxLevel = this.getMyMaxLevel(betAmount);
    var myMaxBet = this.selectedGameMode.getMaxBet() - 1;
    if (myMaxBet > this.balance - 1)
      myMaxBet = this.balance - 1;
    return myMaxBet;
  }

  public getMyMaxLevel(betAmount: number): number {
    if (!this.selectedGameMode.custom) {
      var maxLevel = 1;
      var level = 1;
      this.selectedGameMode.maxBets.forEach(amount => {
        if (betAmount <= this.selectedGameMode.maxBets[level - 1]) maxLevel = level;
        level++;
      })
      return maxLevel;
    }
    else return 1;
  }

  public getLevels(betAmount: number): number[] {
    var levels: number[] = [];
    var maxLevel = this.getMyMaxLevel(betAmount);
    for (var i = maxLevel; i >= 1; i--) levels.push(i)
    return levels;
  }

  public getRows(tiles: number, tilesPerRow: number): number[] {
    var levels: number[] = [];
    var maxLevel = tiles / tilesPerRow;
    for (var i = maxLevel; i >= 1; i--) levels.push(i)
    return levels;
  }

  public selectedGameModeChanged(gameMode: GameMode) {
    this.betAmount = gameMode.betAmount;
    this.LoadLevelMultipliers(gameMode);
    this.LoadMaxBet(gameMode);

    if (gameMode.custom) {
      this.CheckLogin();
    }
  }

  public setActiveGame(game: Game) {
    this.game = game;
    if (this.selectedGameMode.id != this.game.game_mode) {
      this.selectedGameMode = this.gameModes[this.game.game_mode];
      this.selectedGameModeChanged(this.selectedGameMode);
    }
    this.betAmount = this.game.bet_amount;
  }

  public getPreciseAmount(amount: number) {
    if (amount < 1000)
      return amount.toPrecision(3);
    else
      return amount.toPrecision(4);
  }

  public async open(content) {
    this.modalService.open(content);
  }

  public async executeScript(script: string): Promise<IScriptResult> {
    try {
      var scope = {
        tiles: this.selectedGameMode.tiles,
        previousTile: this.autoPlayLastTile,
        balance: this.balance,
        betAmount: this.betAmount,
        gamesWon: this.autoPlayGamesWon,
        gamesLost: this.autoPlayGamesLost,
        gamesCashout: this.autoPlayGamesCashout,
        gameMode: this.selectedGameMode.name,
        level: this.game.level + 1,
        previousBomb: this.autoPlayLastBomb,
        maxBet: this.getMyMaxBet(this.betAmount),
        levels: this.getMyMaxLevel(this.betAmount),
        lastGameStatus: this.autoPlayLastGameStatus, //cashout, won, lost
        context: this.autoPlayCustomScriptContext,
        canCashout: this.game.level > 0 && this.selectedGameMode.enableCashout == true
      }

      var f: IScriptResult = this.scriptService.executeScript(scope, script)
      this.autoPlayCustomScriptContext = f.context;
      this.autoPlayCustomScriptError = null;
      this.scriptService.storeScript(script, this.address);
      return f;
    }
    catch (err) {
      this.autoPlayCustomScriptError = err;
    }
  }
}
