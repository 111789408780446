// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
import { base, halloween, christmas } from '../app/theme/theme.json';


export const environment = {
  production: false,
  nid: 3,
  network_url: 'https://bicon.net.solidwallet.io/api/v3',
  transaction_url: 'https://bicon.tracker.solidwallet.io/transaction/',
  score_address: 'cx3229b3d35428b24ed05434d4b325e922b4cbad9e',
  theme: 'base',
  themeJson: base,
  //base, christmas, easter, new_year, halloween
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
