import { Injectable, EventEmitter } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CookieService {
  public setCookie(cname: string, cvalue: string, hours: number) {
    let d = new Date();
    d.setTime(d.getTime() + hours * 60 * 60 * 1000); // (exdays * 24 * 60 * 60 * 1000));
    let expires = 'expires=' + d.toUTCString();
    // document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    window.top.document.cookie = cname + '=' + cvalue + ';path=/'; // disabling expires key to remove cookie once the tab is closed
  }
  
  public getCookie(cname: string) {
    let name = cname + '=';
    let ca = window.top.document.cookie.split(';');
  
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
  
    return '';
  }
  
  public checkCookie() {
    let user = this.getCookie('token');
    if (user !== '') {
      return user;
    } else {
      return null;
    }
  }
  
}
