export class GameMode { 
    id: number;
    name: string;
    description: string;
    levels: number;
    tiles: number;
    betAmount: number;
    disableBetAmount: boolean = false;
    minBet: number = 0.1;
    maxBets: number[] = [5,5,5,5,5,5];
    levelMultipliers: number[] = [0,0,0,0,0,0,0];
    enableCashout: boolean = true;
    enabled: boolean = true;
    extendedProperties: any;
    custom: boolean = false;
    customOptions: any; 
    customValue: any; 

    //Needs to be overriden with the winning rules
    public checkWin(selectedTile: number, scoreTile: number, level: number) {
        return false;
    }

    //Needs to be overriden with the winning rules
    public checkLevel(level: number) {
        return "easy";
    }

    //Needs to be overriden
    public setCustomValue(customValue: any) {
    }

    public GetTilesEnumerator() {
        var tilesArray: number[] = [];
        for (var i = 1; i <= this.tiles; i++) tilesArray.push(i)
        return tilesArray;
    }
    
    public GetLevelsEnumerator() {
        var levelsArray: number[] = [];
        for (var i = 1; i <= this.levels; i++) levelsArray.push(i)
        return levelsArray;
    }
    
    //Needs to be overriden with the winning rules
    public getMaxBet(): number {
        return this.maxBets[0];
    }
}
