import { Injectable, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import IconService, { IconConverter } from 'icon-sdk-js'
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IconexService {
    private iconexEvent : EventEmitter<any> = new EventEmitter();
    private subscriptions: Subscription;

    constructor() {        
        this.registerForIconexEvents();
    }

    private registerForIconexEvents() {
        const eventHandler = event => {
            const { type, payload } = event.detail;
            this.iconexEvent.emit(payload)
            this.subscriptions.unsubscribe();
            delete this.subscriptions
        }

        // Publish to top Iconbet frame, works when loaded in iconbet
        window.top.addEventListener('IFRAME_RESPONSE', eventHandler);

        // Publish to local frame, works when doing localdev
        window.addEventListener('ICONEX_RELAY_RESPONSE', eventHandler);
    }

    private sendIconexEvent(type: string, payload?: any) {
        // Subscribe to current frame, works when loaded in main frame, works for local dev
        let customEvent = new CustomEvent('ICONEX_RELAY_REQUEST', {
            detail: { 
                type: type,
                payload: payload
            }
        });
        window.dispatchEvent(customEvent);

        // Subscribe to top Iconbet frame, works when loaded in iconbet frame
        customEvent = new CustomEvent('IFRAME_REQUEST', {
            detail: { 
                type: type,
                payload: payload
            }
        });
        window.top.dispatchEvent(customEvent);
    }

    private addSubsciption(subscription: Subscription) {
        if (!this.subscriptions)
            this.subscriptions = subscription;
        else 
            this.subscriptions.add(subscription);
    }

    public async hasAccount(): Promise<boolean> {
        this.sendIconexEvent('REQUEST_HAS_ACCOUNT');

        return new Promise<boolean>((resolve, reject) => {
            this.addSubsciption(this.iconexEvent.subscribe(a => resolve(a.hasAccount)));
        });
    }

    public async hasAddress(address: string): Promise<boolean> {
        this.sendIconexEvent('REQUEST_HAS_ADDRESS', address);

        return new Promise<boolean>((resolve, reject) => {
            this.addSubsciption(this.iconexEvent.subscribe(a => resolve(a.hasAddress)));
        });
    }

    public async selectAddress() : Promise<string> {
        this.sendIconexEvent('REQUEST_ADDRESS');

        return new Promise<string>((resolve, reject) => {
            this.addSubsciption(this.iconexEvent.subscribe(a => resolve(a)));
        });
    }

    public async callTransaction(from: string, to: string, method: string, value: number, params = {}): Promise<any> {
        const paramObject = {
            model: JSON.stringify({
                name: method,
                params: params
            })
        }

        const transaction = this.icxCallTransactionBuild(from, to, "action", value, 50000000, paramObject)
        const jsonRpcQuery = {
            jsonrpc: '2.0',
            method: 'icx_sendTransaction',
            params: IconConverter.toRawTransaction(transaction),
            id: 1234
        }
        this.sendIconexEvent('REQUEST_JSON-RPC', jsonRpcQuery);

        return new Promise<any>((resolve, reject) => {
            this.addSubsciption(this.iconexEvent.subscribe(a => resolve(a)));
        });
    }

    private icxCallTransactionBuild(from, to, method, value, stepLimit, params = {}) {
        let callTransactionBuilder = new IconService.IconBuilder.CallTransactionBuilder()
            .from(from)
            .to(to)
            .value(IconConverter.toHex(IconService.IconAmount.of(value, IconService.IconAmount.Unit.ICX).toLoop()))
            .stepLimit(IconConverter.toBigNumber(stepLimit))
            .nid(IconConverter.toBigNumber(environment.nid))
            .nonce(IconConverter.toBigNumber(1))
            .version(IconConverter.toBigNumber(3))
            .timestamp((new Date()).getTime() * 1000)
            .method(method)

        // Optional "params" field
        if (Object.keys(params).length !== 0) {
            callTransactionBuilder = callTransactionBuilder.params(params)
        }

        return callTransactionBuilder.build()
    }
}