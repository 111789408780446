import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Theme } from '../entities/theme'

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

    private theme: Theme;

  constructor() {
      this.theme = environment.themeJson as unknown as Theme;
      this.SetIFrameColour();
    

  }

    public GetColour(mode: string): string {
        if (mode == "easy") return this.GetEasyColour();   
        if (mode == "medium") return this.GetMediumColour(); 
        if (mode == "hard") return this.GetHardColour();
        if (mode == "quick") return this.GetQuickColour();    
    }

    public GetSafeColour(mode: string): string  {
        if (mode == "easy") return this.GetEasySafeColour();   
        if (mode == "medium") return this.GetMediumSafeColour(); 
        if (mode == "hard") return this.GetHardSafeColour();
        if (mode == "quick") return this.GetQuickSafeColour();   
    }

    private GetEasyColour(): string
    {
      return this.theme.Tiles.EasyColour
    }

    private GetEasySafeColour(): string
    {
        return this.theme.Tiles.EasySafeColour;
    }

    private GetMediumColour(): string
    {
        return this.theme.Tiles.MediumColour;
    }
    
    private GetMediumSafeColour(): string
    {
        return this.theme.Tiles.MediumSafeColour;
    } 

    private GetHardColour(): string
    {
        return this.theme.Tiles.HardColour;
    } 

    private GetHardSafeColour(): string
    {
        return this.theme.Tiles.HardSafeColour;
    } 

    private GetQuickColour(): string
    {
        return this.theme.Tiles.QuickColour;
    } 

    private GetQuickSafeColour(): string
    {
        return this.theme.Tiles.QuickSafeColour;
    } 

    public SetIFrameColour() 
    {
        let color = parent.document.getElementsByClassName("embed-responsive embed-responsive-3by2")[0] as HTMLDListElement;
        color.style.setProperty("background-color", this.theme.iFrameColour, "important");
    } 
}