import { Injectable } from '@angular/core';
import IconService, { HttpProvider, IconBuilder, IconAmount, IconConverter } from 'icon-sdk-js';
import { async } from '@angular/core/testing';
import { IconContractService } from './icon.service';
import { IconexService } from './iconex.service';
import { Game } from '../entities/game';
import { environment } from '../../environments/environment';
import { GameMode } from '../entities/game-mode';
import { Promo } from '../entities/promo';

@Injectable({
    providedIn: 'root'
})
export class GameService {

    constructor(
        public iconService: IconContractService,
        public iconexService: IconexService
    ) { }

    public async StartGame(address: string, amount: number, gameMode: number): Promise<Game> {
        var params = {
            game_mode: gameMode,
        }

        var result = await this.iconexService.callTransaction(address, environment.score_address, 'create_new_game', amount, params);
        if (result && result.result) {
            const txHash = result.result;
            var txResult = await this.iconService.getTransaction(txHash);
            if (txResult.failure) {
                throw `${txResult.failure.message}. Click <a href='${environment.transaction_url}${txHash}' target='_blank'><u>here</u></a> to view details`;
            }
            var gameData = JSON.parse(txResult.eventLogs[txResult.eventLogs.length - 1].indexed[1]);
            var game = new Game(this.iconService.toInt(gameData.game_mode));
            game.game_id = this.iconService.toInt(gameData.game_id);
            game.player_address = gameData.player_address;
            game.status = this.iconService.toInt(gameData.status);
            game.level = this.iconService.toInt(gameData.level);
            game.bet_amount = this.iconService.toBigInt(gameData.bet_amount);
            game.balance = this.iconService.toBigInt(gameData.balance);
            game.active_game_num = this.iconService.toInt(gameData.active_game_num);
            game.bombs = gameData.bombs;
            game.selected_tiles = gameData.selected_tiles;
            return game;
        }
        else {
            if (result && result.message)
                throw `Unable to create new game : ${result.message}`;
            else
                throw `Unable to create new game`;
        }
    }

    public async Cashout(address: string, gameId: number): Promise<any> {
        var params = {
            active_game_num: gameId,
        }

        var result = await this.iconexService.callTransaction(address, environment.score_address, 'cash_out', 0, params);
        if (result && result.result) {
            const txHash = result.result;
            var txResult = await this.iconService.getTransaction(txHash);
            if (txResult.failure && txResult.failure.message) {
                throw `${txResult.failure.message}. Click <a href='${environment.transaction_url}${txHash}' target='_blank'><u>here</u></a> to view details`;
            }
        }
        else {
            if (result && result.message)
                throw `Unable to cash out : ${result.message}`;
            else
                throw `Unable to cash out`;
        }
    }

    public async getOpenGames(address: string): Promise<Game[]> {
        var games: Game[] = [];

        var gamesResponse = await this.iconService.getScoreMethod(environment.score_address, 'get_open_games_by_address', { player_address: address });
        gamesResponse.forEach(gameData => {
            var game = new Game(this.iconService.toInt(gameData.game_mode));
            game.game_id = this.iconService.toInt(gameData.game_id);
            game.player_address = gameData.player_address;
            game.status = this.iconService.toInt(gameData.status);
            game.level = this.iconService.toInt(gameData.level);
            game.bet_amount = this.iconService.toBigInt(gameData.bet_amount);
            game.balance = this.iconService.toBigInt(gameData.balance);
            game.active_game_num = this.iconService.toInt(gameData.active_game_num);
            game.bombs = gameData.bombs;
            game.selected_tiles = gameData.selected_tiles;
            games.push(game);
        });

        return games;
    }

    public async selectTile(address: string, gameId: number, squareId: number, userSeed: string): Promise<any> {
        var params = {
            active_game_num: gameId,
            square_id: squareId,
            user_seed: userSeed
        }

        var result = await this.iconexService.callTransaction(address, environment.score_address, 'select_tile', 0, params);
        if (result && result.result) {
            const txHash = result.result;
            var txResult = await this.iconService.getTransaction(txHash);
            if (txResult.failure && txResult.failure.message) {
                throw `${txResult.failure.message}. Click <a href='${environment.transaction_url}${txHash}' target='_blank'><u>here</u></a> to view details`;
            }

            var firstLog = txResult.eventLogs[0];
            var lastLog = txResult.eventLogs[txResult.eventLogs.length-1];

            if (firstLog.indexed[0].indexOf('SelectedSquareResult') >=0) {
                return this.iconService.toInt(firstLog.indexed[1]);
            } else if (lastLog.indexed[0].indexOf('SelectedSquareResult') >=0) {
                return this.iconService.toInt(lastLog.indexed[1]);
            }
        } else {
            if (result && result.message)
                throw `Unable to select tile : ${result.message}`;
            else
                throw `Unable to select tile`;
        }
    }

    public async customBet(address: string, squareId: number, tiles: number, userSeed: string, amount: number): Promise<any> {
        var params = {
            number_of_tiles: tiles,
            square_id: squareId,
            user_seed: userSeed
        }

        var result = await this.iconexService.callTransaction(address, environment.score_address, 'custom_bet', amount, params);
        if (result && result.result) {
            const txHash = result.result;
            var txResult = await this.iconService.getTransaction(txHash);
            if (txResult.failure && txResult.failure.message) {
                throw `${txResult.failure.message}. Click <a href='${environment.transaction_url}${txHash}' target='_blank'><u>here</u></a> to view details`;
            }
            
            var firstLog = txResult.eventLogs[0];
            var lastLog = txResult.eventLogs[txResult.eventLogs.length-1];

            if (firstLog.indexed[0].indexOf('SelectedSquareResult') >=0) {
                return this.iconService.toInt(firstLog.indexed[1]);
            } else if (lastLog.indexed[0].indexOf('SelectedSquareResult') >=0) {
                return this.iconService.toInt(lastLog.indexed[1]);
            }
        } else {
            if (result && result.message)
                throw `Unable to select tile : ${result.message}`;
            else
                throw `Unable to select tile`;
        }
    }

    public async getMinBet(): Promise<number> {
        var result = await this.iconService.getScoreMethod(environment.score_address, 'get_min_bet_allowed', {});
        if (result) {
            return this.iconService.toBigInt(result);
        };
    }

    public async getMaxBet(gameMode: number): Promise<number[]> {
        var params = {
            game_mode: "0x" + gameMode
        }

        var result = await this.iconService.getScoreMethod(environment.score_address, 'get_max_bet_allowed', params);
        if (result) {
            var maxBetsResponse: number[] = [];
            var maxBets: number[] = JSON.parse(result);
            maxBets.forEach(maxBet => maxBetsResponse.push(this.iconService.toBigInt(maxBet)));
            return maxBetsResponse.reverse();
        };
    }

    public async getCustomMaxBet(tiles: number): Promise<number> {
        var params = {
            number_of_tiles: this.iconService.toHex(tiles)
        }

        var result = await this.iconService.getScoreMethod(environment.score_address, 'get_max_bet_custom_game', params);
        if (result) {
            return this.iconService.toBigInt(result);
        };
    }

    public async getLevelMultipliers(gameMode: number): Promise<number[]> {
        var params = {
            game_mode: "0x" + gameMode
        }

        var result = await this.iconService.getScoreMethod(environment.score_address, 'get_level_multipliers', params);
        if (result) {
            return JSON.parse(result);;
        };
    }

    public async checkPromoEnabled(): Promise<boolean> {
        var result = await this.iconService.getScoreMethod(environment.score_address, 'get_promo_info', {});
        if (result) {
            const promoInfo = result;
            if (promoInfo && promoInfo.promo_status) {
                const promoStatus = this.iconService.toInt(promoInfo.promo_status);
                return promoInfo.promo_status == 1;
            }
        };
    }
    
    public async getPromoInfo(): Promise<Promo> {
        var promo = new Promo();

        var result = await this.iconService.getScoreMethod(environment.score_address, 'get_promo_info', {});
        if (result) {
            const promoInfo = result;
            if (promoInfo) {
                promo.promo_entry_value = this.iconService.toBigInt(promoInfo.promo_entry_value);
                promo.promo_status = this.iconService.toInt(promoInfo.promo_status);
                promo.promo_jackpot_amount = this.iconService.toInt(promoInfo.promo_jackpot_amount);
                //promo.bombs_per_level = this.iconService.toInt(promoInfo.bombs_per_level);
                promo.number_of_levels = this.iconService.toInt(promoInfo.number_of_levels);
                promo.promo_win_amount = this.iconService.toBigInt(promoInfo.promo_win_amount);
                promo.number_of_promo_wins = this.iconService.toInt(promoInfo.number_of_promo_wins);
            }
        };

        return promo;
    }
  
    // Never add Async here, it breaks the whole loading screen of the app
    public GetDefaultGameModes(): GameMode[] {
        var modes: GameMode[] = [];

        var mode = new GameMode();
        mode.id = 0;
        mode.name = 'Easy';
        mode.description = 'Easy Game, 4 tiles with 1 bomb per row. (75% odds, x1.313)';
        mode.tiles = 4;
        mode.levels = 6;
        mode.checkWin = (selectedTile, scoreTile, level) => {
            return selectedTile != scoreTile;
        };
        mode.checkLevel = (level) => {
            return "easy";
        };
        mode.betAmount = 1;
        modes.push(mode);

        mode = new GameMode();
        mode.id = 1;
        mode.name = 'Medium';
        mode.description = 'Medium Difficulty, 3 tiles with 1 bomb per row. (67% odds, x1.477)';
        mode.tiles = 3;
        mode.levels = 6;
        mode.checkWin = (selectedTile, scoreTile, level) => {
            return selectedTile != scoreTile;
        };
        mode.checkLevel = (level) => {
            return "medium";
        };
        mode.betAmount = 1;
        modes.push(mode);

        mode = new GameMode();
        mode.id = 2;
        mode.name = 'Hard';
        mode.description = 'Hard Difficulty, 3 tiles with 2 bombs per row. (33% odds, x2.954)';
        mode.tiles = 3;
        mode.levels = 6;
        mode.checkWin = (selectedTile, scoreTile, level) => {
            return selectedTile == scoreTile;
        };
        mode.checkLevel = (level) => {
            return "hard";
        };
        mode.betAmount = 0.5;
        modes.push(mode);

        return modes;
    }

    public async GetAllGameModes(): Promise<GameMode[]> {
        var modes = this.GetDefaultGameModes();

        var mode = new GameMode();
        mode.id = 3;
        mode.tiles = 4;
        mode.levels = 6;
        mode.checkWin = (selectedTile, scoreTile, level) => {
            if (level <= 4)
                return selectedTile != scoreTile;
            return selectedTile == scoreTile;
        };
        mode.checkLevel = (level) => {
            if (level <= 4)
                return "easy";
            return "hard";
        };
        mode.betAmount = 5;
        mode.minBet = 5;
        mode.enableCashout = false;
        //mode.enabled = await this.checkPromoEnabled();
        mode.extendedProperties = await this.getPromoInfo();
        mode.enabled = mode.extendedProperties.promo_status == 1;
        mode.disableBetAmount = true;
        mode.description = `Promo game, ${mode.extendedProperties.number_of_promo_wins} games won so far. Hurry, 500 ICX prize for fixed entry free. (2% odds)`;
        mode.name = `Promo Game (${mode.extendedProperties.number_of_promo_wins}/8)`;
        if (mode.enabled) modes.push(mode);

        mode = new GameMode();
        mode.id = 4;
        mode.name = 'Quick';
        mode.description = 'Quick Mode, only 1 level playable and game is played with 1 transaction only. You select the odds you want to play.';
        mode.levels = 1;
        mode.checkWin = (selectedTile, scoreTile, level) => {
            return selectedTile != scoreTile;
        };
        mode.checkLevel = (level) => {
            return "quick";
        };
        mode.enableCashout = false;
        mode.betAmount = 2;
        mode.custom = true;
        mode.customOptions = [
            {index: 1, value: 8, name:  "(87.5%)"},
            {index: 2, value: 12, name: "(91.7%)"},
            {index: 3, value: 16, name: "(93.8%)"},
            {index: 4, value: 20, name: "(95.0%)"},
            {index: 5, value: 24, name: "(95.8%)"}];
        mode.customValue = mode.customOptions[2];
        mode.tiles = 16;
        mode.setCustomValue = (newCustomValue: any) => {
            mode.customValue = newCustomValue;
            mode.tiles = newCustomValue.value;
        }
        mode.levelMultipliers = [0, 1.126, 1.0745, 1.051, 1.037, 1.028];
        mode.maxBets = [];
        mode.maxBets.push(await this.getCustomMaxBet(8));
        mode.maxBets.push(await this.getCustomMaxBet(12));
        mode.maxBets.push(await this.getCustomMaxBet(16));
        mode.maxBets.push(await this.getCustomMaxBet(20));
        mode.maxBets.push(await this.getCustomMaxBet(24));
        mode.getMaxBet = () => {
            return mode.maxBets[mode.customValue.index-1];
        };
        modes.push(mode);

        return modes;
    }
}