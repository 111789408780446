import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  private _enableNotifications: boolean = false;
  private _browserVisible: boolean = true;

  constructor(private toastr: ToastrService) {
    this.checkVisibility(() => {
      this._browserVisible = this.checkVisibility();
      if (console) console.log(`Page visible : ${this._browserVisible}`)
    });
  }

  showSuccess(message: string, title: string, toasterOnly = false, important = false) {
    if (console) console.log(`SUCCESS NOTIFICATION : ${title}, ${message}`);
    if (this._browserVisible || !this._enableNotifications || toasterOnly) {
      this.toastr.success(message, title, {
        enableHtml: true,
        tapToDismiss: true,
        timeOut: important && !this._browserVisible ? 0 : 8000
      });
    } else {
      var notification = new Notification(title, {
        icon: 'https://daolevels-prod.web.app/assets/images/Thumbnail.png',
        body: message,
      });
    }
  }

  showError(message: string, title: string, toasterOnly = false, important = false) {
    if (console) console.log(`ERROR NOTIFICATION : ${title}, ${message}`);
    if (this._browserVisible || !this._enableNotifications || toasterOnly) {
      this.toastr.error(message, title, {
        enableHtml: true,
        tapToDismiss: true,
        timeOut: important && !this._browserVisible ? 0 : 8000
      });
    } else {
      var notification = new Notification(title, {
        icon: 'https://daolevels-prod.web.app/assets/images/Thumbnail.png',
        body: message,
      });
    }
  }

  showInfo(message: string, title: string, toasterOnly = false, important = false) {
    if (console) console.log(`INFO NOTIFICATION : ${title}, ${message}`);
    if (this._browserVisible || !this._enableNotifications || toasterOnly) {
      this.toastr.info(message, title, {
        enableHtml: true,
        tapToDismiss: true,
        timeOut: important && !this._browserVisible ? 0 : 8000
      });
    } else {
      var notification = new Notification(title, {
        icon: 'https://daolevels-prod.web.app/assets/images/Thumbnail.png',
        body: message,
      });
    }
  }

  showWarning(message: string, title: string, toasterOnly = false, important = false) {
    if (console) console.log(`WARNING NOTIFICATION : ${title}, ${message}`);
    if (this._browserVisible || !this._enableNotifications || toasterOnly) {
      this.toastr.warning(message, title, {
        enableHtml: true,
        tapToDismiss: true,
        timeOut: important && !this._browserVisible ? 0 : 8000
      });
    } else {
      var notification = new Notification(title, {
        icon: 'https://daolevels-prod.web.app/assets/images/Thumbnail.png',
        body: message,
      });
    }
  }

  enableNotifications() {
    if (!Notification)
      this._enableNotifications = false;
    else if (Notification.permission === "granted")
      this._enableNotifications = true;
    else {
      this._enableNotifications = false;
      Notification.requestPermission().then((result: NotificationPermission) => this._enableNotifications = result === "granted");
    }
  }

  disableNotifications() {
    this._enableNotifications = false;
  }

  checkVisibility(onChangeDelegate?): boolean {
    var stateKey, eventKey, keys = {
      hidden: "visibilitychange",
      webkitHidden: "webkitvisibilitychange",
      mozHidden: "mozvisibilitychange",
      msHidden: "msvisibilitychange"
    };
    for (stateKey in keys) {
      if (stateKey in document) {
        eventKey = keys[stateKey];
        break;
      }
    }
    if (onChangeDelegate)
      document.addEventListener(eventKey, onChangeDelegate);
    return !document[stateKey];
  }
}