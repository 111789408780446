<div id="outer-container">
<div id="main-container">
  <div id="container">
    <div class="row">
      <div class="col-md-12">
        <nav class="navbar navbar-dark">
          <div class="btn-group btn-group-toggle" ngbRadioGroup>
            <label ngbButtonLabel class="{{openGames[0].game_id != game.game_id ? 'btn-outline-info' : 'btn-info'}} "
              *ngIf="openGames && openGames.length > 0" placement="top">
              <!-- (click)="LoadGame(openGames[0].game_id)"-->
              <a *ngIf="openGames[0].game_id != game.game_id" target="_blank"
                [href]="windowLocation.origin + windowLocation.pathname + '?game_id=' + openGames[0].game_id">{{'btn.gamenumber' | translate }} 1</a>
              <span *ngIf="openGames[0].game_id == game.game_id">{{'btn.gamenumber' | translate }} 1</span>
            </label>
            <label ngbButtonLabel class="{{openGames[1].game_id != game.game_id ? 'btn-outline-info' : 'btn-info'}}"
              *ngIf="openGames && openGames.length > 1" placement="top">
              <!-- (click)="LoadGame(openGames[1].game_id)"-->
              <a *ngIf="openGames[1].game_id != game.game_id" target="_blank"
                [href]="windowLocation.origin + windowLocation.pathname + '?game_id='+openGames[1].game_id">{{'btn.gamenumber' | translate }} 2</a>
              <span *ngIf="openGames[1].game_id == game.game_id">{{'btn.gamenumber' | translate }} 2</span>
            </label>
            <label ngbButtonLabel class="{{openGames[2].game_id != game.game_id ? 'btn-outline-info' : 'btn-info'}}"
              *ngIf="openGames && openGames.length > 2" placement="top">
              <!-- (click)="LoadGame(openGames[2].game_id)"-->
              <a *ngIf="openGames[2].game_id != game.game_id" target="_blank"
                [href]="windowLocation.origin + windowLocation.pathname + '?game_id='+openGames[2].game_id">{{'btn.gamenumber' | translate }} 3</a>
              <span *ngIf="openGames[2].game_id == game.game_id">{{'btn.gamenumber' | translate }} 3</span>
            </label>
            <label ngbButtonLabel class="{{openGames[3].game_id != game.game_id ? 'btn-outline-info' : 'btn-info'}}"
              *ngIf="openGames && openGames.length > 3" placement="top">
              <!-- (click)="LoadGame(openGames[3].game_id)"-->
              <a *ngIf="openGames[3].game_id != game.game_id" target="_blank"
                [href]="windowLocation.origin + windowLocation.pathname + '?game_id='+openGames[3].game_id">{{'btn.gamenumber' | translate }} 4</a>
              <span *ngIf="openGames[3].game_id == game.game_id">{{'btn.gamenumber' | translate }} 4</span>
            </label>
            <label ngbButtonLabel class="{{game && game.level != undefined ? 'btn-outline-info' : 'btn-info'}}"
              *ngIf="!openGames || (openGames && openGames.length < 4)" placement="top">
              <!-- (click)="NewGame()-->
              <a *ngIf="game && game.level != undefined" target="_blank"
                [href]="windowLocation.origin + windowLocation.pathname + '?game_id=-1'">{{'btn.startnewgame' | translate }}</a>
              <span *ngIf="game && game.level == undefined">{{'btn.startnewgame' | translate }}</span>
            </label>
          </div>
        </nav>
      </div>
    </div>

    <div class="row">
      <fa-icon class="bounce faq fa-lg" [icon]="faQuestionCircle" (click)="open(mymodal)" tooltip="{{'tooltip.faq' | translate }}" placement="left"
        style="color:#DDDDDD;"></fa-icon>
      <div class="col sound_icon_positions" style="text-align: left; color:#DDDDDD;">
        <fa-icon *ngIf="playingSFX==true" class="bounce fa-lg" [icon]="faVolume" (click)="PlaySFX(false)"
          tooltip="{{'tooltip.mutesfx' | translate }}" placement="right" style="color:#DDDDDD;"></fa-icon>
        <fa-icon *ngIf="playingSFX==false" class="bounce fa-lg" [icon]="faVolumeMute" (click)="PlaySFX(true)"
          tooltip="{{'tooltip.unmutesfx' | translate }}" placement="right" style="color:#DDDDDD;"></fa-icon>
      </div>

      <div class="col sound_icon_positions" style="text-align: right; color:#DDDDDD;">
        <fa-icon *ngIf="playing==false" class="bounce fa-lg" [icon]="faMusic" (click)="PlayAmbient(true)"
          tooltip="{{'tooltip.play' | translate }}" placement="right" style="color:#DDDDDD;"></fa-icon>
        <fa-icon *ngIf="playing==true" class="bounce fa-lg" [icon]="faMusicSlash" (click)="PlayAmbient(false)"
          tooltip="{{'tooltip.stop' | translate }}" placement="right" style="color:#DDDDDD;"></fa-icon>
      </div>
    </div>

    <div class="row">
      <div>
        <!--class="col-md-1"-->
        <img *ngIf="lostAnimate==true" src="../assets/images/game_over.png" class="fade-in overlay_position">
        <img *ngIf="winAnimate==true && selectedGameMode.id != 3" src="../assets/images/winner.png"
          class="fade-in overlay_position">
        <img *ngIf="winAnimate==true && selectedGameMode.id == 3" src="../assets/images/jackpot.png"
          class="fade-in overlay_position">
      </div>

      <div *ngIf="this.selectedGameMode.custom" style="width: 1200px">
        <div class="col-md-12 tile-row" *ngFor="let level of getRows(selectedGameMode.customValue.value, 4)">
          <div class="tile-col">
            <!-- <fa-icon [icon]="faChevronDoubleRight"></fa-icon> -->
          </div>
          <div class="tile-col">
            <app-tile [game]="game" [level]="1" [tile]="((level - 1) * 4) + 1"
              (tileclick)="SelectTile(((level - 1) * 4) + 1)" [scoreTile]="game.bombTiles[1]"
              [amount]="betAmount * selectedGameMode.levelMultipliers[selectedGameMode.customValue.index]"
              [gameMode]="selectedGameMode" [forceTile]="autoPlayForceTile"></app-tile>
            <app-tile [game]="game" [level]="1" [tile]="((level - 1) * 4) + 2"
              (tileclick)="SelectTile(((level - 1) * 4) + 2)" [scoreTile]="game.bombTiles[1]"
              [amount]="betAmount * selectedGameMode.levelMultipliers[selectedGameMode.customValue.index]"
              [gameMode]="selectedGameMode" [forceTile]="autoPlayForceTile"></app-tile>
            <app-tile [game]="game" [level]="1" [tile]="((level - 1) * 4) + 3"
              (tileclick)="SelectTile(((level - 1) * 4) + 3)" [scoreTile]="game.bombTiles[1]"
              [amount]="betAmount * selectedGameMode.levelMultipliers[selectedGameMode.customValue.index]"
              [gameMode]="selectedGameMode" [forceTile]="autoPlayForceTile"></app-tile>
            <app-tile [game]="game" [level]="1" [tile]="((level - 1) * 4) + 4"
              (tileclick)="SelectTile(((level - 1) * 4) + 4)" [scoreTile]="game.bombTiles[1]"
              [amount]="betAmount * selectedGameMode.levelMultipliers[selectedGameMode.customValue.index]"
              [gameMode]="selectedGameMode" [forceTile]="autoPlayForceTile"></app-tile>
          </div>
          <div class="tile-col">
            <!-- <fa-icon [icon]="faChevronDoubleLeft"></fa-icon> -->
          </div>
        </div>
      </div>

      <div *ngIf="!this.selectedGameMode.custom" style="width: 1200px">
        <div class="col-md-12 tile-row" *ngFor="let level of getLevels(betAmount)">
          <div class="tile-col" *ngIf="game.level>=0">
            <!--style="margin-left: -15px;"-->
            <fa-icon *ngIf="game.level==level-1" [icon]="faChevronDoubleRight"></fa-icon>
          </div>
          <div class="tile-col" *ngFor="let tileNr of selectedGameMode.GetTilesEnumerator()">
            <app-tile [game]="game" [level]="level" [tile]="tileNr" (tileclick)="SelectTile(tileNr)"
              [scoreTile]="game.bombTiles[level]" [amount]="betAmount * selectedGameMode.levelMultipliers[level]"
              [gameMode]="selectedGameMode" [forceTile]="autoPlayForceTile"></app-tile>
          </div>
          <div class="tile-col">
            <fa-icon *ngIf="game.level==level-1" [icon]="faChevronDoubleLeft"></fa-icon>
          </div>
        </div>
      </div>
    </div>

    <div class="row" style="margin-bottom: 5px">
      <div class="col-md-4">
        <div class="form-group-md">
          <span class="balance label">{{'lbl.walletbalance' | translate }}</span>
          <input class="form-control form-control-md transparent-input" type="text" placeholder="{{'placeholder.walletbalance' | translate }}" [value]="balance | number:'1.1-2'" disabled>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group-md">
          <span class="balance label">{{'lbl.multiplier' | translate }}</span>
          <input class="form-control form-control-md transparent-input" type="text" placeholder="place bet" [value]="selectedGameMode.custom ? selectedGameMode.levelMultipliers[selectedGameMode.customValue.index] : (game.level >= 0 ? selectedGameMode.levelMultipliers[game.level + 1] : selectedGameMode.levelMultipliers[1])" disabled>
        </div>
      </div>
      <div class="col-md-4">
        <div class="form-group-md" *ngIf="game.balance==0">
          <span class="balance label">{{'lbl.startingbalance' | translate }}</span>
          <input class="form-control form-control-md transparent-input" type="text" [value]="game.bet_amount | number:'1.1-2'" disabled>
        </div>
        <div class="form-group-md" *ngIf="game.balance > 0">
          <span class="balance label">{{'lbl.gamebalance' | translate }}</span>
          <input class="form-control form-control-md transparent-input" type="text" [value]="game.balance | number:'1.1-2'" disabled>
        </div>
        <div class="form-group-md" *ngIf="selectedGameMode.custom || game.balance == undefined">
          <span class="balance label">{{'lbl.betamount' | translate }}</span>
          <input class="form-control form-control-md transparent-input" type="text" [value]="betAmount | number:'1.1-2'" disabled>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="game.level > 0 && selectedGameMode.enableCashout == true">
      <div class="col-md-12">
        <button type="button" class="btn btn-info btn-block btn-outline-dark" (click)="Cashout()"
          [disabled]="cashoutLoading || tileLoading" style="margin-top: 5px">{{'btn.cashout' | translate }}
          <fa-icon *ngIf="cashoutLoading" [icon]="faSpinner" [spin]="true"></fa-icon>
        </button>
      </div>
    </div>

    <div class="row" *ngIf="game.level == undefined">
      <div class="col-md-12 form-group">
        <div class="d-flex justify-content-between align-items-end amount-label">
          <span class="balance label">{{'lbl.maxbet' | translate }}: {{selectedGameMode.getMaxBet() | number:'1.1-2'}}
            <small>ICX</small></span>
          <div class="pills-group" style="margin-bottom: 2px">
            <span class="bet-pill badge badge-pill badge-info" (click)="betAmount = getDoubleBet(betAmount)">x2</span>
            <span class="bet-pill badge badge-pill badge-info" (click)="betAmount = getHalfBet(betAmount)">1/2</span>
            <span class="bet-pill badge badge-pill badge-info" (click)="betAmount = selectedGameMode.minBet">{{'pill.min' | translate }}</span>
            <span class="bet-pill badge badge-pill badge-info" (click)="betAmount = getMyMaxBet(betAmount)">{{'pill.max' | translate }}</span>
          </div>
        </div>
        <div class="input-group">
          <input type="text" class="form-control transparent-input"
            placeholder="{{'placeholder.minbet' | translate: {'minbet': selectedGameMode.minBet} }}" aria-describedby="basic-addon2"
            [(ngModel)]="betAmount" [readonly]="selectedGameMode.disableBetAmount">
          <div ngbDropdown class="input-group-append" role="group"
            *ngIf="selectedGameMode.custom && game.level == undefined">
            <button class="btn btn-info btn-block btn-outline-dark dropdown-toggle-split"
              ngbDropdownToggle>{{'dropdown.customvalue' | translate:{'value': selectedGameMode.customValue.value, 'name': selectedGameMode.customValue.name} }}</button>
            <div class="dropdown-menu" ngbDropdownMenu>
              <button ngbDropdownItem *ngFor="let customOption of selectedGameMode.customOptions"
                (click)="selectedGameMode.setCustomValue(customOption)">{{'dropdown.customvalue' | translate:{'value': customOption.value, 'name': customOption.name} }}</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="game.level == undefined && !selectedGameMode.custom">
      <div class="col-md-12 btn-group">
        <button type="button" *ngIf="game && game.level == undefined" class="btn btn-info btn-outline-dark"
          style="margin-top: 5px" (click)="StartGame(betAmount, selectedGameMode.id)" [disabled]="gameLoading">
           {{'btn.placebet' | translate }} 
          <fa-icon [icon]="faSpinner" [spin]="true" *ngIf="gameLoading"></fa-icon>
        </button>
      </div>
    </div>

    <div class="row mt-1" *ngIf="game.level == undefined">
      <div class="col-md-12 btn-group btn-group-toggle" ngbRadioGroup name="gameMode" [(ngModel)]="selectedGameMode">
        <label ngbButtonLabel class="btn btn-dark btn-outline-info" *ngFor="let gameMode of gameModes" placement="top"
          [ngbTooltip]="'tooltip.'+ gameMode.name | translate">
          <input id="gameMode" ngbButton type="radio" [value]="gameMode" (click)="selectedGameModeChanged(gameMode)">
            {{'gamemode.'+ gameMode.name | translate}}
        </label>
      </div>
    </div>

    <br>
    <div class="row" *ngIf="address" style="margin-bottom: 5px;">
      <div class="col-md-12"
        *ngIf="autoPlay == true && (!autoStop && autoPlayCurrentGame > 0 || autoStop && autoStopGames == 0)">
        <div class="progress">
          <div class="progress-bar bg-danger progress-bar-striped progress-bar-animated" role="progressbar"
            [style]="'width: '+((autoPlayGamesLost/autoPlayCurrentGame) * 100)+'%'" aria-valuenow="10" aria-valuemin="0"
            aria-valuemax="500">{{autoPlayGamesLost}} {{'progressbar.lost' | translate }}</div>
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
            [style]="'width: '+((autoPlayGamesCashout/autoPlayCurrentGame) * 100)+'%'" aria-valuenow="100"
            aria-valuemin="0" aria-valuemax="500">{{autoPlayGamesCashout}} {{'progressbar.cashout' | translate }}</div>
          <div class="progress-bar bg-success progress-bar-striped progress-bar-animated" role="progressbar"
            [style]="'width: '+((autoPlayGamesWon/autoPlayCurrentGame) * 100)+'%'" aria-valuenow="10" aria-valuemin="0"
            aria-valuemax="500">{{autoPlayGamesWon}} {{'progressbar.won' | translate }} </div>
          <div class="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar"
            [style]="'width: '+(((autoPlayCurrentGame-autoPlayGamesLost-autoPlayGamesCashout-autoPlayGamesWon)/autoPlayCurrentGame) * 100)+'%'"
            aria-valuenow="10" aria-valuemin="0" aria-valuemax="500"><span
              *ngIf="autoPlayCurrentGame <= 11">{{'progressbar.playing' | translate }}</span></div>
        </div>
      </div>
      <div class="col-md-12"
        *ngIf="autoPlay == true && autoStop == true && autoStopGames > 0 && autoPlayCurrentGame > 0">
        <div class="progress">
          <div class="progress-bar bg-danger progress-bar-striped progress-bar-animated" role="progressbar"
            [style]="'width: '+((autoPlayGamesLost/autoStopGames) * 100)+'%'" aria-valuenow="10" aria-valuemin="0"
            aria-valuemax="500">{{autoPlayGamesLost}} {{'progressbar.lost' | translate }} </div>
          <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar"
            [style]="'width: '+((autoPlayGamesCashout/autoStopGames) * 100)+'%'" aria-valuenow="100" aria-valuemin="0"
            aria-valuemax="500">{{autoPlayGamesCashout}} {{'progressbar.cashout' | translate }}</div>
          <div class="progress-bar bg-success progress-bar-striped progress-bar-animated" role="progressbar"
            [style]="'width: '+((autoPlayGamesWon/autoStopGames) * 100)+'%'" aria-valuenow="10" aria-valuemin="0"
            aria-valuemax="500">{{autoPlayGamesWon}}  {{'progressbar.won' | translate }}</div>
          <div class="progress-bar bg-info progress-bar-striped progress-bar-animated" role="progressbar"
            [style]="'width: '+(((autoStopGames-autoPlayGamesLost-autoPlayGamesCashout-autoPlayGamesWon)/autoStopGames) * 100)+'%'"
            aria-valuenow="10" aria-valuemin="0" aria-valuemax="500"><span
              *ngIf="((autoStopGames-autoPlayGamesLost-autoPlayGamesCashout-autoPlayGamesWon)/autoStopGames) * 100 > 8">{{'progressbar.playing' | translate }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="address">
      <div class="col-md-4">
        <div class="pretty p-switch p-fill">
          <input type="checkbox" [(ngModel)]="autoPlay" (ngModelChange)="autoPlayReset()" />
          <div class="state p-info">
            <label class="simplelabel">{{'toggle.autoplay' | translate }}</label>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="address">
      <div class="col-md-4">
        <label class="my-1 mr-2 simplelabel" for="stratSelect">{{'lbl.tilestrategy' | translate }}</label>
      </div>
      <div class="col-md-8">
        <select class="custom-select my-1 mr-sm-2 btn-dark btn-outline-info" id="stratSelect"
          [(ngModel)]="autoPlayStrat">
          <option selected value="Always1">{{'tilestrategy.always' | translate }} 1</option>
          <option value="Always2">{{'tilestrategy.always' | translate }} 2</option>
          <option value="Always3">{{'tilestrategy.always' | translate }} 3</option>
          <option value="AlwaysLast" *ngIf="this.selectedGameMode.tiles >= 4">{{'tilestrategy.alwaysprevious' | translate }}</option>
          <option value="Diag1">{{'tilestrategy.gonext' | translate }}</option>
          <option value="SecondMod">{{'tilestrategy.clock' | translate }}</option>
          <option value="Custom" *ngIf="!this.selectedGameMode.custom">{{'tilestrategy.defineown' | translate }}</option>
          <option value="Script">{{'tilestrategy.script' | translate }}</option>
        </select>
      </div>
    </div>

    <div class="row" *ngIf="autoPlayStrat == 'Custom'">
      <div class="col-md-4">
        <label class="my-1 mr-2 simplelabel" for="cashoutLevel">{{'lbl.customstrategy' | translate }}</label>
      </div>
      <div class="col-md-8">
        <input type="text" class="form-control transparent-input" placeholder="eg. 1,3,2,1,3,1"
          aria-describedby="basic-addon2" [(ngModel)]="autoPlayCustomStrat"
          [pattern]="'([1-'+selectedGameMode.tiles+'],){'+(getMyMaxLevel(betAmount)-1)+'}[1-'+selectedGameMode.tiles+']'"
          #customStrat="ngModel">
        <span *ngIf="customStrat.errors?.pattern" class="badge badge-danger">{{'errormessage.customvalidation' | translate }}</span>
      </div>
    </div>

    <div class="row" *ngIf="address && selectedGameMode.enableCashout && autoPlayStrat!='Script'">
      <div class="col-md-4">
        <label class="my-1 mr-2 simplelabel" for="cashoutLevel">{{'lbl.cashoutlevel' | translate }}</label>
      </div>
      <div class="col-md-8">
        <select class="custom-select my-1 mr-sm-2 btn-dark btn-outline-info" id="cashoutLevel"
          [(ngModel)]="autoPlayCashout">
          <option selected value="6">{{'cashoutstrategy.nocashout' | translate }}</option>
          <option value="5">5</option>
          <option value="4">4</option>
          <option value="3">3</option>
          <option value="2">2</option>
          <option value="1">1</option>
        </select>
      </div>
    </div>

    <div class="row" *ngIf="autoPlayStrat=='Script'">
      <div class="col-md-12">
        <ngx-codemirror [(ngModel)]="autoPlayCustomScript" (ngModelChange)="executeScript(autoPlayCustomScript)"
          [options]="{lineNumbers: true,theme: 'material',mode: 'javascript'}">
        </ngx-codemirror>
        <select id="demolist" style="position: absolute; right: 15px; top: 5px;z-index: 25;" [(ngModel)]="autoPlayExampleScript" (ngModelChange)="autoPlayCustomScript=autoPlayExampleScript; executeScript(autoPlayCustomScript);">
          <option value="#" >Examples...</option>
          <option [value]="example.script" *ngFor="let example of scriptService.getSamples()">{{example.name}}</option>
        </select>
        <span *ngIf="autoPlayCustomScriptError" class="badge badge-danger">{{autoPlayCustomScriptError}}</span>
      </div>
    </div>

    <br>
    <div class="row" *ngIf="address && autoPlay == true">
      <div class="col-md-4">
        <div class="pretty p-switch p-fill">
          <input type="checkbox" [(ngModel)]="autoStop" />
          <div class="state p-danger">
            <label class="simplelabel">{{'toggle.autostop' | translate }}</label>
          </div>
        </div>
      </div>

    </div>

    <div class="row" *ngIf="address && autoPlay == true && autoStop == true">
      <div class="col-md-4">
        <label class="my-1 mr-2 simplelabel" for="autoStopGames">{{'lbl.gamestoplay' | translate }}</label>
      </div>
      <div class="col-md-8">
        <select class="custom-select my-1 mr-sm-2 btn-dark btn-outline-info" id="autoStopGames"
          [(ngModel)]="autoStopGames">
          <option selected value="10">10</option>
          <option value="20">20</option>
          <option value="50">50</option>
          <option value="100">100</option>
          <option value="200">200</option>
          <option value="500">500</option>
          <option value="0">{{'autostop.infinite' | translate }}</option>
        </select>
      </div>
    </div>

    <div class="row" *ngIf="address && autoPlay == true && autoStop == true">
      <div class="col-md-4">
        <label class="my-1 mr-2 simplelabel" for="autoStopBalance">{{'lbl.walletbalance' | translate }}</label>
      </div>
      <div class="col-md-8">
        <input id="rangeInput" class="form-control-range" type="range" [(ngModel)]="autoStopBalance" min="1"
          [max]="balance" style="margin-top:8px">
      </div>
    </div>
    <div class="row" *ngIf="address && autoPlay == true && autoStop == true">
      <div class="col-md-4"></div>
      <div class="col-md-8">
        <label class="simplelabel">{{autoStopBalance}} <small>ICX</small></label>
      </div>
    </div>
  </div>
</div>

  <ng-template #mymodal let-modal>
    <div class="modal-header">
      <h4 class="modal-title">
        DAOlevels
        <div class="divide"></div>
      </h4>
      <button type="button" class="close" (click)="modal.dismiss('Cross click')">
        <span>X</span>
      </button>
    </div>
    <div class="modal-body">
      {{'faq.firstparagraph' | translate }}
      <br /> <br />
      <strong>{{'faq.easy' | translate }}</strong> {{'faq.easydescription' | translate }} <br />
      <strong>{{'faq.medium' | translate }} </strong> {{'faq.mediumdescription' | translate }}<br />
      <strong>{{'faq.hard' | translate }} </strong> {{'faq.harddescription' | translate }} <br />
      <strong>{{'faq.quick' | translate }} </strong> {{'faq.quickdescription' | translate }} <br />
      <br />
      <strong>{{'faq.incomplete' | translate }} </strong>– {{'faq.incompletedescription' | translate }}

      <br /> <br />
      <strong>{{'faq.increasingwager' | translate }}</strong> {{'faq.increasingwagerdescription' | translate }}
    </div>
    <div class="modal-footer">
      <div class="avatar"></div>
    </div>
  </ng-template>