import { Injectable } from '@angular/core';
import { LocalStorage } from '@ngx-pwa/local-storage';
import { IScriptResult } from '../entities/script-result';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  constructor(private storage: LocalStorage) {
  }

  public executeScript(scope: any, script: string) {
    this.checkScriptSafe(script);
    return Function('"use strict";' + script).bind(scope)();
  }

  public storeScript(script: string, key: string) {
    this.storage.setItem("userScript", this.encryptData(script, key)).subscribe(() => { });
  }

  public async restoreScript(key: string): Promise<string> {
    const script = await this.storage.getItem<string>("userScript").toPromise()
    return this.decryptData(script + '', key);
  }

  public getWarning() : string {
    return `// WARNING, this scr!pt is here 
// for your convenience, but it is also very risky. 
// IconBet takes no responsiblity, and there are no 
// refunds. Be sure to test your strategy with small 
// amounts first or go onto Testnet to be safe. You 
// can get more help on telegram @t.me/ICONbetGameTesting`;
  }

  public getSamples(): any {
    var examples = [
      {
        name: 'Always Select Tile 1',
        script: this.getWarning() + `\r\n\r\n// Always return tile 1
return {tile: 1};`
      },
      {
        name: 'Select Tile Based on Clock',
        script: `// Get seconds of the current
// Time, modulus the seconds with
// the number of Tiles available in this game mode
var seconds = (new Date()).getSeconds();
var tile = (seconds % this.tiles) + 1;
return {tile: tile};`
      },
      {
          name: 'Winning Streak',
          script: `// Every time we win, increase
// the bet amount by 0.1, every 
// time we lose, decrease bet amount by 1 icx
// this works better on Quick mode
var newBetAmount = this.betAmount;
if (this.lastGameStatus == 'won' || this.lastGameStatus == 'cashout')
    newBetAmount += 0.1;
if (this.lastGameStatus == 'lost')
    newBetAmount -= 1;
// Make sure we do not drop below 0.5 ICX bet amount
if (newBetAmount <= 0.5) newBetAmount = 0.5;
// Make sure we do not exceed 50 ICX bet amount
if (newBetAmount >= 50) newBetAmount = 50;
// If this bot is playing in easy/medium/hard mode, 
// force a cashout at level 1
var cashout = false;
if (this.level == 1) cashout = true;
return {tile: 1, 
        betAmount: newBetAmount,
        cashout: cashout};`
 },{
        name: 'Mining TAP',
        script: `// WARNING!! this is for Quick Mode optimised for 24 tiles
// this is from the text box on the UI or you can override it here base amount is 5
var newBetAmount = 5;
// based on rule of averages you should lose back to back roughly once in every 550 games (approx) on a 24 tile game
// you can adjust this as you see fit
var b2bLikelihood = 200; 
// this is the amount we bet after a loss and the b2b likelihood is high
var b2bLikelihoodBet = 20;
// this is the bet straight after a loss if the moving average is under the currrent average of landing on a bomb (ie not on a long winning streak)
var betAmountAfterLossBase = 50;
// this is the bet amount straight after a loss if you are now on a winning streak (defined as not landing on a bomb more than the average (1/24))
var betAmountAfterLossWS = 0.5;
// what tile you want to always select
var tile = 3;
        
if(!this.context) {
  this.context = {};
}
if(!this.context.winning){
    this.context.winning = 0;
}
if(!this.context.numgames) {
  this.context.numgames = 0;
}
if(!this.context.moving_average) {
  this.context.moving_average = [];
}
if(!this.context.games_since_b2b_avg) {
  this.context.games_since_b2b_avg = [];
}
if(!this.context.games_since_b2b) {
  this.context.games_since_b2b = 0;
}

var currentAvg = 0;
var temp = 0;
for(var i=0;i<this.context.moving_average.length;i++) {
    var value = temp + this.context.moving_average[i];
    var temp = value;	
}

var currentB2bAvg = 0;
var temp2 = 0
for(var i=0;i<this.context.games_since_b2b_avg.length;i++) {
    var value = temp + this.context.games_since_b2b_avg[i];
    var temp2 = value;	
  }
  
  // here you are able to see your current statistics if you go to debug mode on the browser (F12 on most browsers)
  currentAvg = temp / this.context.moving_average.length;
  console.log("Winning streak average: "+ currentAvg);
  currentB2bAvg = temp2 / this.context.games_since_b2b_avg.length;
  console.log("Average of B2B: "+ currentB2bAvg);

this.context.numgames++;

if (this.lastGameStatus == 'won') {
    newBetAmount = newBetAmount;
    this.context.winning++;
    this.context.games_since_b2b++;
}
if(this.context.winning >= currentAvg-2) {
    newBetAmount = betAmountAfterLossWS;
}
if (this.lastGameStatus == 'lost') {
    this.context.moving_average.push(this.context.winning)
  if(this.context.winning == 0) {
    this.context.games_since_b2b = 0;
      if(this.context.games_since_b2b_avg.length == 0) {
        this.context.games_since_b2b_avg.push(this.context.numgames);
        } else {
        var remove_last = this.context.numgames - this.context.games_since_b2b_avg[this.context.games_since_b2b.length-1]; 
        this.context.games_since_b2b_avg.push(remove_last);
        }
  } else {
      // if the previous tile as also a loss we are approaching B2B territory.
      // if the amount of games since last b2b is equal to our predefined value then only bet a small amount incase of a B2B
      if(this.context.games_since_b2b >= b2bLikelihood) {    
        newBetAmount = b2bLikelihoodBet;
      } else {
        // statiscally speaking we are not close to a likely B2B lose then bet larger to recover the previous loss 
        newBetAmount = betAmountAfterLossBase;
      }
    }
  this.context.winning = 0;   
}

console.log("Games since last B2B: " + this.context.games_since_b2b);
console.log("Winning Streak: "+ this.context.winning);
console.log("Winning Streaks: " + JSON.stringify(this.context.moving_average));
console.log("Games played until B2B " + JSON.stringify(this.context.games_since_b2b_avg));

return {tile: tile,
        context: this.context,
        betAmount: newBetAmount };`
      },
       {
         name: 'Lightning Strikes',
         script: `// Lightning never strikes twice
// in the same spot. Select the 
// Tile that was previously a bomb
// If this bot is playing in easy/medium/hard mode, 
// force a cashout at level 1
var cashout = false;
if (this.level == 1) cashout = true;
return {tile: this.previousBomb, 
        betAmount: this.betAmount,
        cashout: cashout};`
       },
       {
          name: 'Automated Recovery',
          script: `//Increase bet automatically after a loss
// this is from the text box on the UI or you can override it here base amount is 5
var betAmount = 5;
// this is bet amount used on the next bet after a bomb, this is to help recover the loss
var betAfterLoss = 100;
// what tile you want to always select
var tile = 3;
        
if (this.lastGameStatus == 'won') {
    betAmount = betAmount;
}

if (this.lastGameStatus == 'lost') {
    betAmount = betAfterLoss;
}
  
// If this bot is playing in easy/medium/hard mode, 
// force a cashout at level 1
var cashout = false;
if (this.level == 1) cashout = true;

return {tile: tile, 
        betAmount: betAmount,
        cashout: cashout};`
      },
{
  name: 'FAQ',
  script: `// List of built in properties
// available to code your bot

this.tiles //Number of tiles to choose from
this.previousTile // Tile used in previous game / level
this.balance // Wallet balance
this.betAmount // Current bet amount 
this.gamesWon // Total games won in this Autoplay session
this.gamesLost // Total games lost in this Autoplay session
this.gamesCashout // Total games cashed out in this Autoplay session
this.gameMode // Name of the current game mode - Easy, Medium, Hard, Quick
this.level // Level of current game, always 1 for Quick
this.previousBomb // Bomb tile of previous game / level
this.maxBet // Max bet allowed
this.levels // Total levels in current game
this.lastGameStatus //Status of previous game - cashout, won, lost
this.context // A custom object you can store any custom values you want
this.canCashout // true or false, whether you can cashout or not

// Return options
return {tile: 1, // The next tile to select, NB!! if this is missing, autoplay stops
        context: {}, // Any custom values you want to retain
        betAmount: 1, // Change bet amount for next bet
        cashout: true}; // when true, game will cash out`
},
    ];

    return examples;
  }

  private encryptData(data: string, key: string) {
    try {
      return CryptoJS.AES.encrypt(data, key).toString();
    } catch (e) {
      console.log(e);
    }
  }

  private decryptData(data: string, key: string) {
    try {
      const bytes = CryptoJS.AES.decrypt(data, key);
      if (bytes.toString()) {
        return bytes.toString(CryptoJS.enc.Utf8);
      }
      return data;
    } catch (e) {
      console.log(e);
    }
  }

  private checkScriptSafe(script: string) {
    var res = script.match(/(document|window|getelement|http|script|eval|ActiveXObject|setTimeout|setInterval|clearTimeout|clearInterval|Function|arguments|constructor|global|prototype)/gi);
    if (res)
      throw 'Unsafe code detected, cannot continue';  
  }
}
